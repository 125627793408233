import React, {useState, useEffect} from "react";
import {Trans, useTranslation} from 'gatsby-plugin-react-i18next';
import Layout from "../../../../components/layout";
import Seo from "../../../../components/seo";
import {StaticImage} from "gatsby-plugin-image";
import {graphql} from 'gatsby';
import BANManagement from "../../../../components/pages/BANManagement";
import FaqSection from "../../../../components/pages/FaqSection";
import BookConsultation from "../../../../components/pages/BookConsultation";

const IndexPage = () => {

  const {t} = useTranslation();

  useEffect(() => {
    // code
  }, [])


  return (
      <Layout>
        <Seo
            title={t('orbit_title_tag')}
            description={t('orbit_desc')}
        />
        <div className="products orbit-page">
          <section class="hero-pages">
            <div class="container">
              <div class="grid-two-col">
                <div className="grid-two-col-item-single is-reversable">
                  <div>
                    <StaticImage
                        src="../../../../images/locks/product-pages/orbit/logo-gray-orbit.png"
                        loading={'lazy'}
                        width={163}
                        height={53}
                        quality={100}
                        formats={["auto", "webp", "avif"]}
                        alt="Orbit logo"
                    />
                    <h1><Trans>orbit_headline</Trans></h1>
                    <p><Trans>orbit_headline_copy</Trans></p>
                    <BookConsultation />
                  </div>
                </div>
                <div className="grid-two-col-item-single">
                  <div>
                    <StaticImage
                        src="../../../../images/locks/product-pages/orbit/orbit-quarter.png"
                        loading={'lazy'}
                        width={240}
                        height={112}
                        quality={100}
                        formats={["auto", "webp", "avif"]}
                        alt="Orbit smart lock"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div id="pivot" class="section-delimeter container"/>
          <section className={'appearance-functionality'}>
            <div className="container">
              <h1 className={'headline'} dangerouslySetInnerHTML={{__html: t('orbit_variations')}}/>

              <div className="grid-two-col">
                <div className="grid-two-col-item-single page-locking-options">
                  <h3><Trans>locking_options</Trans></h3>
                  <br/>
                  <div className="thumbs">
                    <div>
                      <StaticImage
                          src="../../../../images/locks/product-pages/orbit/orbit-bolt.png"
                          loading={'lazy'}
                          width={109}
                          height={115}
                          quality={100}
                          formats={["auto", "webp", "avif"]}
                          alt="Aspire famiily"
                          className={'Cleo with deadbolt'}
                      />
                      <p><Trans>deadbolt</Trans></p>
                    </div>
                    <div>
                      <StaticImage
                          src="../../../../images/locks/product-pages/orbit/orbit-latch.png"
                          loading={'lazy'}
                          width={109}
                          height={115}
                          quality={100}
                          formats={["auto", "webp", "avif"]}
                          alt="Aspire famiily"
                          className={'Cleo with latch'}
                      />
                      <p><Trans>latch</Trans></p>
                    </div>
                  </div>
                </div>
                <div className="grid-two-col-item-single page-finish-options">
                  <h3><Trans>finish_options</Trans></h3>
                  <br/>
                  <div className="thumbs orbit">
                    <div className="thumbs swatches">
                      <div>
                        <StaticImage
                            src="../../../../images/locks/product-pages/orbit/body-style.png"
                            loading={'lazy'}
                            width={107}
                            height={107}
                            quality={100}
                            formats={["auto", "webp", "avif"]}
                            alt="Aspire famiily"
                            className={'cleo black'}
                        />
                        <p><Trans>black</Trans></p>
                      </div>
                      <div>
                        <StaticImage
                            src="../../../../images/locks/product-pages/orbit/body-style-white.png"
                            loading={'lazy'}
                            width={107}
                            height={107}
                            quality={100}
                            formats={["auto", "webp", "avif"]}
                            alt="Aspire famiily"
                            className={'cleo white'}
                        />
                        <p><Trans>white</Trans></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br/>
            <span className={'body-style-brochure'}>
                      <a href={'https://cdn.mediavalet.com/usva/digilock/galAYtoVfEG80rVGrdnBxg/1VFVVTqE_ECGOeyZacnjEQ/Original/DIGILOCK-ORBIT-NETWORK-PRODUCT-BROCHURE.pdf'} target={'_blank'} className={'orbit-brochure-button'}>
                        <Trans>download_brochure</Trans>
                      </a>
                      </span>
          </section>
          <div class="section-delimeter container"/>
          <section className="page-benefits">
            <div className="container">
              <h1>
                <Trans>orbit_functions</Trans>
              </h1>
              <div className="grid-two-col">
                <div className="grid-two-col-item-single is-reversable">
                  <div class="parent">
                    <div>
                      <h3 dangerouslySetInnerHTML={{__html: t('product_highlights')}}/>
                      <ul>
                        <li dangerouslySetInnerHTML={{__html: t('Advanced_auto_unlock')}}/>
                        <li dangerouslySetInnerHTML={{__html: t('Coin_sized_low_profile_design')}}/>
                        <li dangerouslySetInnerHTML={{__html: t('Touch_free_and_auto_opening')}}/>
                        <li dangerouslySetInnerHTML={{__html: t('Multi_directional_locking_mechanism')}}/>
                      </ul>
                      <h3 dangerouslySetInnerHTML={{__html: t('Access_Options')}}/>
                      <ul>
                        <li dangerouslySetInnerHTML={{__html: t('rfid')}}/>
                        <li dangerouslySetInnerHTML={{__html: t('Mobile_Access')}}/>
                        <li dangerouslySetInnerHTML={{__html: t('electronic_key')}}/>
                      </ul>
                    </div>
                    <div>
                      <h3><Trans>cleo_functionality</Trans></h3>
                      <ul>
                        <li dangerouslySetInnerHTML={{__html: t('Shared_Use_Mode')}}/>
                        <li dangerouslySetInnerHTML={{__html: t('Assigned_Use_Mode')}}/>
                        <li dangerouslySetInnerHTML={{__html: t('Reservation_Mode') + '<br />(' + t('networked_locks_only') + ')'}}/>
                      </ul>
                      <h3><Trans>cleo_installation</Trans></h3>
                      <ul>
                        <li dangerouslySetInnerHTML={{__html: t('cleo_installation_list_1')}}/>
                        <li dangerouslySetInnerHTML={{__html: t('cleo_installation_list_3')}}/>
                        <li dangerouslySetInnerHTML={{__html: t('Inside_mount')}}/>
                        <li dangerouslySetInnerHTML={{__html: t('orbit_1/2_(12.7_mm)_motorized_deadbolt_or_deadlatch')}}/>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="grid-two-col-item-single image">
                  <div>
                    <StaticImage
                        src="../../../../images/locks/orbit-family.png"
                        loading={'lazy'}
                        width={450}
                        height={311}
                        quality={100}
                        formats={["auto", "webp", "avif"]}
                        alt="Orbit famiily"
                        className={'Orbit locks'}
                    />
                  </div>
                </div>
              </div>
            </div>
            <br/>
            <BookConsultation />
          </section>
          <div id="pivot" className="section-delimeter container"/>
          <section className="page-management">
            <BANManagement product={'Orbit'}/>
          </section>
          <div id="pivot" className="section-delimeter container"/>
          <section className="faq-section">
            <FaqSection/>
          </section>
        </div>
      </Layout>
  )
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
